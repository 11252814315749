@import "~bootstrap/scss/bootstrap.scss";

img.logo {
  margin: auto;
}

div.body-container {
  @extend .d-flex;
  @extend .w-100;
  @extend .h-100;
  @extend .p-3;
  @extend .mx-auto;
  @extend .flex-column;

  @include media-breakpoint-up(xl) {
    max-width: 50%;
  }
}

@import 'login';

html, body {
  height: 100%;
  background-color: #333;
}

body {
  @extend .text-center;

  display: -ms-flexbox;
  display: flex;
  color: #fff;
  text-shadow: 0 .05rem .1rem rgba(0, 0, 0, .5);
  box-shadow: inset 0 0 5rem rgba(0, 0, 0, .5);
}

a,
a:focus,
a:hover {
  color: #fff;
}

.btn-secondary,
.btn-secondary:hover,
.btn-secondary:focus {
  color: #333;
  text-shadow: none; /* Prevent inheritance from `body` */
  background-color: #fff;
  border: .05rem solid #fff;
}

header.top {
  margin-bottom: 2rem;

  .brand {
    @media (min-width: 48em) {
      float: left;
    }

    margin-bottom: 0;

    a:hover, a:visited, a:active {
      text-decoration: none;
    }
  }

  .nav-link {
    padding: .25rem 0;
    font-weight: 700;
    color: rgba(255, 255, 255, .5);
    background-color: transparent;
    border-bottom: .25rem solid transparent;
    margin-left: 1rem;

    &.active {
      color: #fff;
      border-bottom-color: #fff;
    }
  }

  .nav-link:hover, .nav-link:focus {
    border-bottom-color: rgba(255, 255, 255, .25);
  }

  .nav {
    @media (min-width: 48em) {
      float: right;
    }
  }
}

main {
  &.home {
    @extend .mt-auto;
  }

  .inner {
    padding: 0 1.5rem;
    
    &.btn-lg {
      padding: .75rem 1.25rem;
      font-weight: 700;
    }
  }
}

div.alert {
  text-shadow: none;
  color: #000;

  a { 
    color: #000;
  }

  ul {
    list-style: none;
  }
}

footer {
  @extend .mt-auto;
  color: rgba(255, 255, 255, .5);
}

table[data-role="short-url-list"] {
  tr.disabled {
    @extend .table-danger;

    &, &:hover, a, a:hover {
      color: #000;
      text-shadow: none;
    }
  }
}