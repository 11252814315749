body.login {
  background: #FFF;
  text-shadow: unset;
  box-shadow: unset;

  color: #000;

  a {
    color: #000;
  }

  ul {
    list-style: none;
    padding-left: 0;
  }
}